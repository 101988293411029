var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_detail"),
        "destroy-on-close": true,
        closable: false,
        width: "820px"
      }
    },
    [
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.propSource,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              }
            },
            scroll: { y: 320 },
            "row-key": function(r, i) {
              return i
            }
          }
        },
        [
          _c(
            "a-table-column",
            { key: "no", attrs: { width: "75px", "data-index": "no" } },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_number_short")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "invoiceDate",
              attrs: { "data-index": "invoiceDate" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [
                      _vm._v(_vm._s(_vm._f("date")(text)))
                    ])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_return_date")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "returnNumber",
              attrs: { "data-index": "returnNumber" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_return_number")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "returnAmount",
              attrs: { "data-index": "returnAmount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [
                      _vm._v(_vm._s(_vm._f("currency")(text)))
                    ])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_return_amount")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "a-button",
        {
          key: "save",
          attrs: { slot: "footer", type: "primary" },
          on: { click: _vm.closeModal },
          slot: "footer"
        },
        [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }