























































import Vue from "vue";
export default Vue.extend({
  name: "InvoiceModalReturn",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    propSource: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    closeModal(): void {
      this.$emit("input", false);
    },
  },
});
